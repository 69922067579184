import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useCartId, useCreateCart, useAddToCart, useUpdateCart, useConfig } from '@kega/sps-core';

import { t } from '../../../../lib/translations';

import { default as AddToCartButton } from '../../../../components/buttons/AddToCart';

import useTagManager from '../../../../hooks/useTagManager';
import useItemCategories from '../../../../hooks/useItemCategories';

import AddToCartSmall from '../../../../components/buttons/AddToCartSmall';
import Portal from '../../../../components/portal/Portal';
import Counter from '../../../../components/counter/Counter';
import { MaxIcon, MinIcon } from '../../../../components/icons';

import useUi from '../../../../hooks/useUi';
import useCartItem from '../../../../hooks/useCartItem';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useUserToken from '../../../../hooks/useUserToken';
import useUserId from "../../../../hooks/useUserId";

import Wishlist from "../wishlist/Wishlist";
import aa from 'search-insights'

import classes from './AddToCart.module.css';
import classNames from 'classnames';

const AddToCart = ({ product, name, Merk, assortmentMinQuantity, objectID, classNameLabel }) => {
    
    const { openCart } = useUi();
    const { state, pathname } = useLocation();
    const { match } = useMediaQuery('(max-width: 768px)');

    const getCartId = useCartId();
    const getUserId = useUserId();
    const { createCart } = useCreateCart();
    const { addToCart, error } = useAddToCart();
    const { updateCart } = useUpdateCart();
    const { ecommerceEvent } = useTagManager();

    const { get } = useUserToken();
    const user = get();

    const productCode = product?.code;
    const outOfStock = product?.stock?.stockLevelStatus === 'outOfStock';
    const cart_item = useCartItem(product ? product.code : null);
    const stockLevel = product?.stock?.stockLevel || 9999;
    const minQuantity = assortmentMinQuantity || 1;
    const maxQuantity = stockLevel - (cart_item?.quantity || 0);
    const queryID = state?.queryID;
    const productName = product?.formattedName || name
    const itemCategories = useItemCategories(product.categoryPath);
    const packagingUnit = product?.packagingUnit;
    const config = useConfig();

    const storeCode = config.get('storeCode');
    const { index: algoliaIndex } = config.get('algolia');

    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [quantityError, setQuantityError] = useState('');
    const formRef = useRef(null);
    const errorRef = useRef(null);
    const winePackagingUnit = packagingUnit === 'fles';

    const onAddToCart = async (event) => {
        event.preventDefault();

        if (!product || outOfStock) {
            return;
        }

        const newQuantity = Number(quantity);
        const updatedQuantity = cart_item ? (cart_item.quantity + newQuantity) : newQuantity;

        if ((Number(updatedQuantity) > stockLevel)) {
            setQuantityError(t('productdetails.addtocart.error_stock', { stockLevel }));
            return;
        } else if (Number(updatedQuantity) < minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_min_qty'));
            return;
        } else if (Number(updatedQuantity) % minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_order_qty'));
            return;
        } else {
            setQuantityError('');
        }

        setLoading(true);

        const cartId = getCartId();
        const userId = getUserId();

        if (cartId === null) {
            await createCart({ userId });
        }

        if (cart_item) {
            await updateCart({
                product: {
                    code: product.code
                },
                quantity: updatedQuantity,
                entryNumber: cart_item.entryNumber,
                userId: userId
            });
        } else {
            await addToCart({
                product: {
                    code: product.code
                },
                quantity: updatedQuantity,
                userId: userId
            });
        }

        try {
            aa(queryID ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs', {
                userToken: 'user-' + user,
                eventName: queryID
                    ? 'PDP: Product Added to Cart after Search'
                    : 'PDP: Product Added to Cart',
                queryID: queryID,
                index: algoliaIndex + '-product-' + storeCode,
                objectIDs: [objectID],
            });
    
        } catch (error) {
            //
        }

        try {
            const { code, priceData } = product;

            ecommerceEvent('add_to_cart', {
                value: priceData?.value,
                currency: config.get('currency'),
                items:[{
                    item_id: code,
                    item_name: productName,
                    item_brand: Merk ?? product?.manufacturer,
                    price: priceData?.value,
                    item_category: itemCategories[0],
                    item_category2: itemCategories[1],
                    item_category3: itemCategories[2],
                    item_category4: itemCategories[3],
                    item_category5: itemCategories[4],
                }]
            });
        } catch (error) {
            console.log(error)
        }

        openCart();

        formRef.current.reset();

        setQuantity(minQuantity);

        setLoading(false);

    };

    const onQuantityChange = (newValue) => {
        const newQuantity = Number(newValue);
        const updatedQuantity = cart_item ? (cart_item.quantity + newQuantity) : newQuantity;

        if ((Number(updatedQuantity) > stockLevel)) {
            setQuantityError(t('productdetails.addtocart.error_stock', { stockLevel }));
        } else if (Number(updatedQuantity) < minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_min_qty'));
        } else if (Number(updatedQuantity) % minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_order_qty'));
        } else {
            setQuantityError('');
        }

        setQuantity(newQuantity);
    };

    useEffect(() => {
        formRef.current.reset();
        setQuantityError('');
    }, [pathname]);

    useEffect(() => {
        setQuantity(minQuantity);
    }, [minQuantity]);

    return (
        <>
            <div className={classes.row}>
                <span className={classNames(classes.label, classNameLabel)}>{t('productdetails.qty')}</span>
                <Counter
                    name="quantity"
                    value={maxQuantity < minQuantity ? 0 : quantity}
                    min={minQuantity}
                    max={maxQuantity}
                    step={minQuantity}
                    disabled={maxQuantity < minQuantity}
                    parentCallback={onQuantityChange}
                    autoAdjustQty={false}
                    minComponent={MinIcon}
                    maxComponent={MaxIcon}
                />
                <span className={classes.assortment_name}>{winePackagingUnit && t('productdetails.unit_label')}</span>
            </div>

            <form ref={formRef} onSubmit={onAddToCart} noValidate>

                <div className={classes.actions}>
                    <AddToCartButton type="submit" style={{ width: '100%', marginRight: '10px' }} disabled={!product || outOfStock} loading={loading}>{t('productdetails.addtocart_button')}</AddToCartButton>
                    <Wishlist code={productCode} className={classes.wishlistbutton} />
                </div>

                <div className={classes.error} ref={errorRef}>
                    {
                        error
                        &&
                        <>
                            {
                                error?.data?.errors
                                    ?
                                    <>
                                        {
                                            error.data.errors.map(({ message }, index) => {
                                                return <p key={'e'+index}>{ message }</p>
                                            })
                                        }
                                    </>
                                    :
                                    <p>{ error.message }</p>
                            }
                        </>
                    }
                    {
                        quantityError
                        &&
                        <p>{ t(quantityError) }</p>
                    }
                </div>
            </form>

            {
                match &&
                <Portal>
                    <div className={classes.sticky}>
                        <div className={classes.row}>
                            <span className={classes.label}>{t('productdetails.qty')}</span>
                            <Counter
                                name="quantity"
                                value={maxQuantity < minQuantity ? 0 : quantity}
                                min={minQuantity}
                                max={maxQuantity}
                                step={minQuantity}
                                disabled={maxQuantity < minQuantity}
                                parentCallback={onQuantityChange}
                                trigger="blur"
                                minComponent={MinIcon}
                                maxComponent={MaxIcon}
                            />
                            <span className={classes.assortment_name}>{winePackagingUnit && t('productdetails.unit_label')}</span>
                        </div>
                        <form onSubmit={onAddToCart}>
                            <AddToCartSmall type="submit" disabled={!product || outOfStock} loading={loading} />
                        </form>
                    </div>
                </Portal>
            }
        </>
    );
}

export default AddToCart;