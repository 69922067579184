import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

import { t } from '../../../lib/translations';

import useFetchWishlist from '../../../hooks/wishlist/useFetchWishlist';
import useTranslatePath from "../../../hooks/useTranslatePath";

import { Col, Row } from '../../../components/grid';
import ProductTile from '../../../components/product/ProductTile';
import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

import classes from './Wishlist.module.css';

const Wishlist = () => {
    const setBreadcrumb = useOutletContext();
    const { wishlist } = useFetchWishlist();
    const { getPath } = useTranslatePath();

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: 'Wensenlijst', url_key: '/account/wishlist' }
        ]);
    }, []);

    return (
        <Row>
            <BuilderSectionLazy model="account-page" url={getPath('/account/wishlist')} />
            {
                Object.entries(wishlist).length === 0 ?
                    <h2 className={classes.message}>{ t('account.wishlist.empty_message') }</h2> :
                    <Col md="10" className={classes.center}>
                        <Row>
                            {
                                Object.keys(wishlist).map((item, index) => {
                                    
                                    let wishlistItem = { 
                                        ...wishlist[item]?.product,
                                        baseCode: wishlist[item]?.product?.code?.replace('variant-', '') ?? ''
                                    }
                                    
                                    return (
                                        <Col className={classes.wishlist_product} xs="6" sm="4" md="3" key={index}>
                                            <ProductTile product={wishlistItem} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
            }
        </Row>
    );
}

export default Wishlist;