import { Button as DefaultButton } from '@kega/sps-elements';
import classNames from 'classnames';

import { ShoppingbagIcon } from '../icons';

import classes from './Button.module.css';

const AddToCart = ({ children, ...props }) => {

    const className = classNames(classes.root, classes.atc);

    return (
        <DefaultButton className={className} {...props} >
            {children}<ShoppingbagIcon size={20} className={classNames(classes['inline-icon'])} title="toevoegen-product" />
        </DefaultButton>
    );
}

export default AddToCart;