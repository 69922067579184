import { Price } from '@kega/sps-components';
import { useConfig } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import classNames from 'classnames';

import classes from './CartTotals.module.css';

const CartTotals = ({ totals }) => {

    const config = useConfig();
    const locale = config.get('locale');
    const currency = config.get('currency');

    const { total, subTotal, totalPriceExclVat, deliveryCost, taxes=[] } = totals ?? {};

    return (
        <div className={classes.root}>


            {
                subTotal
                &&
                <div className={classes.row}>
                    { t('checkout.cart.subtotal') } <Price price={subTotal.value} currency={currency} locale={locale} currencySymbol={false} className={classes.amount} />
                </div>
            }

            {
                deliveryCost
                &&
                <div className={classes.row}>
                    { t('checkout.cart.shippingAmount') } <Price price={deliveryCost.value} currency={currency} locale={locale} currencySymbol={false} className={classes.amount} />
                </div>
            }
            

            {
                totalPriceExclVat
                &&
                <div className={classes.row}>
                    { t('checkout.cart.total_ex_btw') } &nbsp;&nbsp;<span className={classes.label}>{ t('checkout.cart.ex_vat') }</span> <Price price={totalPriceExclVat.value} currency={currency} locale={locale} currencySymbol={false} className={classNames(classes.amount, classes.amount)}/>
                </div>
            }

            {
                taxes.map(({ code, value }) => {
                    return (
                        <div key={code} className={classes.row}>
                            { t('checkout.cart.VAT') }&nbsp;&nbsp;<span className={classes.label}>{Number(code).toFixed(0)} %</span> <Price price={value} currency={currency} locale={locale} currencySymbol={false} className={classNames(classes.amount, classes.amount)} />
                        </div>
                    );
                })
            }

            {
                total
                &&
                <div className={classes.row}>
                    <strong>{ t('checkout.cart.total_phrase') }</strong>&nbsp;&nbsp;<span className={classes.label}>{ t('checkout.cart.incl_vat') }</span> <Price price={total.value} currency={currency} locale={locale} currencySymbol={false} className={classNames(classes.amount, classes.total)} />
                </div>
            }

        </div>
    )
}

export default CartTotals;