import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

import useTranslatePath from '../../../hooks/useTranslatePath';

import { t } from '../../../lib/translations';

import { Row } from '../../../components/grid';
import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

import Newsletter from './newsletter/Newsletter';

const Profile = () => {
    const { getPath } = useTranslatePath();
    const setBreadcrumb = useOutletContext();

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: t('account.profile.header') }
        ]);
    }, []);

    return (
        <Row>
            <BuilderSectionLazy model="account-page" url={getPath('/account/profile')} />
            <Newsletter />
        </Row>
    )
}

export default Profile;