import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import useTranslatePath from "../../../hooks/useTranslatePath";

import { Row } from "../../../components/grid";
import BuilderContentLazy from "../../builder/BuilderContentLazy";

const Return = () => {
    const setBreadcrumb = useOutletContext();
    const { getPath } = useTranslatePath();

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: 'Retouren', url_key: '/account/return' }
        ]);
    }, []);


    return (
        <Row>
            <BuilderContentLazy model="account-page" url={getPath('/account/return')} />
        </Row>
    );
}   

export default Return;