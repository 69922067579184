import { Outlet } from 'react-router-dom';
import { CartItems } from '@kega/sps-cart';

import { Col, Row, Container } from '../../components/grid';
import { t } from '../../lib/translations';

import CartTotals from '../cart/totals/CartTotals';
import Progressbar from './progressbar/Progressbar';

import CartItem from './cart/CartItem';

import classes from './Checkout.module.css';

const Checkout = ({ cart }) => {
   
    return (
        <>
            <div className={classes.top_bar}>
                <Container xl margins g-xs={2} g-md={3}>
                    <Row>

                        <Col md={12}>
                            <Progressbar />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container xl margins g-xs={2} g-md={3}>
                <Row>

                    <Col md={7}>
                        <Outlet />
                    </Col>

                    <Col md={5}>
                        <div className={classes.cart}>

                            <h4>{ t('checkout.cart.header') }</h4>

                            <CartItems itemComponent={CartItem} />

                            <CartTotals totals={cart.totals} />
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default Checkout;