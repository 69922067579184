
import { useMemo, useState, useRef, useEffect } from 'react';
import { useConfig } from '@kega/sps-core';
import { useNavigate, useLocation } from "react-router-dom";
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';

import qs from 'qs';
import classNames from 'classnames';
import algoliasearch from 'algoliasearch/lite';

import aa from 'search-insights'

import useUserToken from '../../../hooks/useUserToken';
import useTagManager from '../../../hooks/useTagManager';

import { SearchIcon, CloseCircleIcon } from '../../../components/icons';
import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';

import Product from './product/Product';
import Category from './category/Category';

import classes from './Search.module.css';

const Search = () => {
    const [ autocompleteState, setAutocompleteState ] = useState({});
    const [ showClear, setShowClear ] = useState(false);
    const { search } = useLocation();
    const { getPath } = useTranslatePath();
    const { get } = useUserToken();
    const { ecommerceEvent, dataLayer } = useTagManager();

    const navigate = useNavigate();
    const inputRef = useRef(null);
    const formRef = useRef(null);

    const config = useConfig();

    const storeCode = config.get('storeCode');

    const { index, appId, apiKey } = config.get('algolia');

    const { q = '' } = qs.parse(search.replace('?', ''), { comma: true })

    const searchClient = useMemo(() => algoliasearch(appId, apiKey), [appId, apiKey]);
    const redirectUrlPlugin = useMemo(() => createRedirectUrlPlugin(), []);

    const filterSources = (query, sources) => {
        // Only search for results if query has at least 3 characters
        return query?.length >= 3
            ? sources
            : [];
    };

    useEffect(() => {
        inputRef.current.value = q;
    }, [search]);

    const autocomplete = useMemo(() => {

        return createAutocomplete({

            placeholder: t('header.search_placeholder'),

            debug: false,

            initialState: {
                query: q
            },

            onSubmit: ({ state, setQuery }) => {
                navigate(getPath(`/search?q=${state.query}`));
                setQuery('');
            },

            onReset: () => {
                setShowClear(false)
            },

            onStateChange({ state }) {
                setAutocompleteState(state);
            },

            getSources({ query }) {
                return filterSources(query, [
                    {
                        sourceId: 'categories',

                        getItems: ({ query }) => {
                            const user = get();

                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + '-category-' + storeCode,
                                    query,
                                    params: {
                                        hitsPerPage: 4,
                                        clickAnalytics: true,
                                        enablePersonalization: true,
                                        userToken: 'user-' + user,
                                    },
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Category key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setQuery } = props;
                            const { code } = item;

                            setQuery('');

                            navigate(getPath(item?.url));
                        }

                    }, {
                        sourceId: 'products',

                        getItems: ({ query }) => {
                            const user = get();

                            return getAlgoliaResults({
                                searchClient,
                                queries: [{
                                    indexName: index + '-product-' + storeCode,
                                    query,
                                    params: {
                                        hitsPerPage: 4,
                                        clickAnalytics: true,
                                        enablePersonalization: true,
                                        userToken: 'user-' + user,
                                        //highlightPreTag: '<mark>',
                                        //highlightPostTag: '</mark>',
                                    },
                                }],
                            });
                        },

                        renderItem: (item, source) => {
                            const { onClick } = autocomplete.getItemProps({ item, source });
                            return <Product key={item.objectID} data={item} onClick={onClick} />
                        },

                        onSelect: (props) => {
                            const { item, setQuery } = props;
                            const {
                                baseCode,
                                name,
                                Merk = '',
                                descriptionHs,
                                price,
                                trustPilotProductOneStar,
                                trustPilotProductTwoStars,
                                trustPilotProductThreeStars,
                                trustPilotProductFourStars,
                                trustPilotProductFiveStars,
                                trustPilotProductReviewAverage,
                                trustPilotProductReviewCount,
                                formattedName = null
                            } = item;
                            const user = get();
                            const productName = formattedName || name;

                            try {
                                aa('clickedObjectIDsAfterSearch', {
                                    userToken: 'user-' + user,
                                    eventName: 'Autocomplete: Product Clicked',
                                    index: index + '-product-' + storeCode,
                                    queryID: item.__autocomplete_queryID,
                                    objectIDs: [item.objectID],
                                    positions: [item.__autocomplete_id],
                                });
    
                            } catch (error) {
                                //
                            }

                            try {
    
                                ecommerceEvent('select_item', {
                                    //item_list_id
                                    //item_list_name
                                    items:[{
                                        item_id: baseCode,
                                        item_name: productName,
                                        item_brand: Merk ?? item?.manufacturer,
                                        price: price
                                    }]
                                });
                            } catch (error) {
                                //
                            }

                            setQuery('');

                            navigate(getPath(item?.url), {
                                state: {
                                    name: productName,
                                    Merk: Merk,
                                    description: descriptionHs,
                                    trustPilotProductOneStar,
                                    trustPilotProductTwoStars,
                                    trustPilotProductThreeStars,
                                    trustPilotProductFourStars,
                                    trustPilotProductFiveStars,
                                    trustPilotProductReviewAverage,
                                    trustPilotProductReviewCount,
                                    queryID: item.__autocomplete_queryID
                                }
                            });
                           
                        }

                    }
                ]);
            },

            plugins: [redirectUrlPlugin]

        })

    }, []);

    const inputProps = autocomplete.getInputProps({ inputElement: inputRef.current })

    const onChange = (event) => {

        inputProps.onChange(event);

        if (event.target.value.length > 0 && !showClear) {
            setShowClear(true);
        } else if (event.target.value.length === 0 && showClear) {
            setShowClear(false);
        }

        if (event.target.value.length > 0) {
            try {
                dataLayer.push({
                    'event': 'search',
                    'search_term': event.target.value
                });
            } catch (error) {
                //
            }
        }
    }

    return (
        <div className={classes.root}>
            <div {...autocomplete.getRootProps({})}>

                <form
                    ref={formRef}
                    {...autocomplete.getFormProps({ inputElement: inputRef.current })}
                >
                    <div className={classNames(classes.search_input, (autocompleteState.isOpen ? classes.search_input_open : null))}>
                        <input type="search" ref={inputRef} {...inputProps} onChange={onChange} />
                        {
                            showClear
                            &&
                            <button className={classes.clear} title="Clear" type="reset">
                                <CloseCircleIcon size={18} />
                            </button>
                        }
                        <button type="submit" ><SearchIcon size={18} color="#72082e" /></button>
                    </div>
                </form>

                {
                    autocompleteState.isOpen
                    &&
                    <div className={classes.results} {...autocomplete.getPanelProps({})}>
                        {
                            autocompleteState.collections.map((collection, index) => {
                                const { source, items } = collection;

                                return (
                                    <div key={`source-${index}`} className={classes.source}>
                                        {
                                            items.length > 0 &&
                                            items.map((item) => {
                                                
                                                const productName = item?.formattedName || item?.name;
                                                
                                                if (source?.renderItem) {
                                                    return source?.renderItem(item, source);
                                                }
                               
                                                return (
                                                    <div
                                                        key={item.objectID}
                                                        className={classes.result_item}
                                                        {...autocomplete.getItemProps({ item, source, })}
                                                    >
                                                        {productName}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                }

            </div>
        </div>
    );

}

export default Search;