import { useEffect } from 'react';

import { connectScrollTo } from 'react-instantsearch-dom';

const ScrollTo = ({ children, hasNotChanged }) => {

    useEffect(() => {
        if (!hasNotChanged) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }, [hasNotChanged])

    return children;
}

export default connectScrollTo(ScrollTo);