import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Loader } from '@kega/sps-elements';

import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';
import useOrders from '../../../hooks/customer/useOrders';

import CustomPagination from '../../../components/custompagination/CustomPagination';
import BuilderSectionLazy from '../../builder/BuilderSectionLazy';
import { Col, Row } from '../../../components/grid';
import Input from '../../../components/form/Input';
import Order from './order/Order';

import classes from './Orders.module.css';

const Orders = () => {
    const setBreadcrumb = useOutletContext();
    const { getPath } = useTranslatePath();
    const { orders, pagination, getOrders, loading } = useOrders();

    const [searchTerm, setSearchTerm] = useState('');

    const dateTo = new Date(Date.now()).toLocaleDateString('en-GB');

    
    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: 'Facturen/bestellingen', url_key: '/account/orders' }
        ]);
        
        getOrdersHandler();
    }, []);
    
    const getOrdersHandler = (currentPage = 0, orderCode = '') => {
        getOrders({
            dateTo,
            currentPage,
            orderCode
        });
        window.scrollTo(0, 0); 
    }
    
    const onSubmit = ({ values: { search } }) => {
        getOrdersHandler(0, search);
    }

    const handlePageChange = (page) => {
        getOrdersHandler(page);
    }

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const onlyNumbers = (value) => {
        return value.replace(/[^0-9]/g, '');
    };

    return (
        <Row>
            <BuilderSectionLazy model="account-page" url={getPath('/account/orders')} />
            {loading ? <Loader variant="primary" /> : 
                <Col md="12" className={classes.orders}>
                    <Col md="5">
                        <Form onSubmit={onSubmit}>
                            <Input 
                                modifiers={[onlyNumbers]}
                                name="search" 
                                className={classes.search} 
                                placeholder={t('account.account_overview.banners.orders.search')}
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                        </Form>
                    </Col>
                    {orders?.length ? 
                        orders.map((order) => <Order key={order.code} { ...order } />) : 
                        <h2 className={classes.message}>{ t('account.orders.empty_list') }</h2>
                    }
                    {pagination?.totalResults > 5 && (
                        <CustomPagination {...pagination} onPageChange={handlePageChange} />
                    )}
                </Col> 
            }
        </Row>
    )
}

export default Orders;