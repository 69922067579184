import { useState, useEffect } from 'react';
import { Form } from '@kega/sps-elements';
import { useChangePassword } from '@kega/sps-core';
import { useOutletContext } from "react-router-dom";

import { t } from '../../../../lib/translations';
import { Col, Row } from '../../../../components/grid';

import Input from '../../../../components/form/Input';
import Button from '../../../../components/buttons/Button';

import classes from './EditPassword.module.css';

const EditPassword = () => {
    const [ noMatch, setNoMatch ] = useState(false);
    const { changePassword, error: resetError, loading } = useChangePassword();
    const setBreadcrumb = useOutletContext();

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: t('account.edit_password.header') }
        ]);
    }, []);

    const onSubmit = async ({ valid, values, resetForm }) => {
        const { oldpassword, password, password2 } = values;

        if (noMatch) { setNoMatch(false); }
        
        if (valid && password === password2) {
            await changePassword({ newPassword: password, oldPassword: oldpassword });
            resetForm();
        } else {
            setNoMatch(true);
        }
    }

    return (
        <Row>
            <Col md="12">
                <h1 className={classes.h1}>{ t('account.edit_password.header') }</h1>

                <Form className={classes.form} onSubmit={onSubmit}>
                    
                    {
                        resetError
                        &&
                        <span className={classes.error_message}>
                            { 
                                resetError?.data.errors.map(({ message }) => {
                                    return  <>{ message }<br/></>;
                                })
                            }
                        </span>
                    }
                    
                    <Input name="oldpassword" type="password" label={ t('account.edit_password.oldpassword_label') } required rootClassName={classes.input} errorMessage={ t('form.error_not_empty') } />
                    <Input name="password" type="password" label={ t('account.edit_password.password1_label') } required rootClassName={classes.input} errorMessage={ t('form.error_not_empty') } />
                    <Input 
                        name="password2" 
                        type="password" 
                        label={ t('account.edit_password.password2_label') } 
                        required 
                        rootClassName={classes.input} 
                        error={noMatch} 
                        errorMessage={(noMatch ? t('account.edit_password.error_password_not_equal') : t('form.error_not_empty'))}
                    />

                    <Button
                        type="submit"
                        variant="primary"
                        loading={loading}
                    >
                        { t('account.edit_password.save') }
                    </Button>
                </Form>
            </Col>
        </Row>
    )
}

export default EditPassword;

/*pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,30}$"*/